/**
 * @typedef CommonMetatagVariable
 *
 * @property {string} code
 * @property {string} text
 * @property {string} tempId
 */

/**
 * @param {CommonMetatagVariable} form
 * @returns {CommonMetatagVariable}
 */
export function initCommonMetatagVariable(form = {}) {
    return {
        ...(form || {}),
        code: form?.code || null,
        text: form?.text || null,

        tempId: form?.tempId ?? Math.random().toString().slice(2),
    };
}
