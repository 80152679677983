export const COMMON_METATAG_TAG_TYPES = {
    title: {
        value: "title",
        title: "title",
        data: {},
    },
    meta: {
        value: "meta",
        title: "meta",
        data: {
            content: [],
            name: [
                //
                "author",
                "content-language",
                "copyright",
                "description",
                "keywords",
                "msapplication-TileImage",
                "msapplication-TileColor",
                "subject",
                "theme-color",
                "title",
                "viewport",
            ],
            property: [
                //
                "og:description",
                "og:image",
                "og:title",
                "og:type",
            ],
        },
    },
};
