import { COMMON_METATAG_TAG_TYPES } from "../constants";

export function initCommonMetatag(form = {}) {
    let tags = [...(form?.tags || [{ type: COMMON_METATAG_TAG_TYPES.title.value }, { type: COMMON_METATAG_TAG_TYPES.meta.value }])].map(initCommonMetatag_Tag);

    return {
        ...(form || {}),

        path: form?.path || null,

        tags,

        code: initCommonMetatag_Code.bind(this)(tags),

        uses: form?.uses ?? true,
    };
}

export function initCommonMetatag_Tag(form = {}) {
    return {
        ...(form || {}),

        type: form?.type || null,

        data: [...(form?.data || [{}])].map(initCommonMetatag_Tag_Data),

        uses: form?.uses ?? true,

        tempId: form?.tempId ?? Math.random().toString().slice(2),
    };
}

export function initCommonMetatag_Tag_Data(form = {}) {
    return {
        ...(form || {}),

        key: form?.key || null,
        value: form?.value || null,

        tempId: form?.tempId ?? Math.random().toString().slice(2),
    };
}

export function initCommonMetatag_Code(tags = []) {
    return [
        ...(tags || []).map(({ type, data }) => {
            let data_slots = data.filter(({ key }) => key == "slot");
            let data_props = data.filter(({ key }) => key != "slot");

            let tag = `<${type}`;

            if (0 < data_props.length) {
                let prop = data_props.map(({ key, value }) => {
                    try {
                        value = (value || "").interpolate(this?.variables_object || {});
                    } catch {
                        value = value;
                    }
                    return `${key}="${value}"`;
                });
                prop = prop.join(" ");
                tag += ` ${prop}`;
            }

            if (0 < data_slots.length) {
                let needsWrap = 1 < data_slots.length || 0 < data_props.length;

                tag += ">";

                if (needsWrap) tag += "\n";

                let slot = data_slots.map(({ value }) => {
                    try {
                        value = (value || "").interpolate(this?.variables_object || {});
                    } catch {
                        value = value;
                    }
                    return value;
                });
                slot = slot.join("\n");
                tag += `${slot}`;

                if (needsWrap) tag += "\n";

                tag += `</${type}>`;
            } else {
                tag += " />";
            }

            return tag;
        }),
    ].join("\n");
}
