import * as banners from "./banners";
import metatags from "./metatags";
import * as popups from "./popups";
import * as setting from "./setting";

export const common = {
    ...banners,
    metatags,
    ...popups,
    ...setting,
};
