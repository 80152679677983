import axios from "@/plugins/axios";

let url = "/api/v1/common/metatags";

export const metatags = {
    get(data) {
        return axios.get(url, data).then((result) => result.data);
    },
};

export default metatags;
