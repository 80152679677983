import * as alarms from "./alarms";
import * as banners from "./banners";
import * as blacklists from "./blacklists";
import metatags from "./metatags";
import * as popups from "./popups";
import * as receptions from "./receptions";
import * as setting from "./setting";

export const common = {
    ...alarms,
    ...banners,
    ...blacklists,
    metatags,
    ...popups,
    ...receptions,
    ...setting,
}
