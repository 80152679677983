import api from "@/api";

/** @type {import("vuex").Module} */
export const metatags = {
    namespaced: true,

    state: {
        variables: [],
        /** @type {import("vue-meta").MetaInfo} */
        metaInfo: {},
    },

    getters: {
        variables_object(state) {
            return state.variables.reduce((object, { code, text }) => ({ ...object, [code]: text }), {});
        },
    },

    mutations: {
        setVariables(state, payload) {
            state.variables = payload;
        },

        setMetaInfo(state, payload) {
            state.metaInfo = payload;
        },
    },

    actions: {
        async getVariables({ commit }) {
            const { setting } = await api.console.common.setting.get();
            commit("setVariables", setting.metatag?.variables);
        },
        async setVariables({ commit, dispatch }, items) {
            const {
                setting: { metatag },
            } = await api.console.common.setting.get();
            metatag.variables = items;

            await api.console.common.setting.put({ metatag });

            await dispatch("getVariables");
        },
    },
};

export default metatags;
